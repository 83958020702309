import React from 'react'
import styled from 'styled-components'
import Videos from '../../../components/Videos'

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  .padded-section {
    p {
      + p {
        margin-top: 8px;
      }

      + div {
        margin-top: 22px;
      }
    }
  }

  .gatsby-image-wrapper {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    section {
      + .padded-section {
        padding-top: 0;
      }
    }
  }
`

const PastReunions = ({ pageContext: { videos } }) => {

  return (
    <Wrapper className="wrap">
      <section className="section">
        <h2 className="headline--reunion center-align">Past Reunions</h2>
        <p>
          Reunions are a time to come together for a weekend filled with learning, connection, and purpose.
          Thanks to all our Artists who have made our Reunions such an unforgettable experience.
        </p>
        {videos?.length > 0 &&
          <Videos style={{ marginTop: '32px' }} className="section" videos={videos} />
        }
      </section>
    </Wrapper>
  )
}

export default PastReunions
